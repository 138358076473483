<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('FILTERING.IPACCESS_LISTS.PREFERENCE')">
          <template v-slot:toolbar>
            <b-button
              variant="primary"
              class="mr-4"
              @click="openCreateModal()"
              v-if="canAdd"
            >
              {{ $t("COMMON.NEW") }}
            </b-button>
            <b-button
              variant="danger"
              v-b-modal.confirm-1
              v-if="canRemove"
              :disabled="preferenceTable.selected.length == 0"
            >
              {{ $t("COMMON.DELETE") }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-row class="table-filter">
              <b-col sm="6" class="my-3">
                <b-form-group
                  :label="$t('FILTERING.IPACCESS_LISTS.SELECT_IPTYPE')"
                  label-for="select-iptype"
                >
                  <b-form-select
                    id="select-iptype"
                    v-model="iptype.selected"
                    :options="iptypeData"
                    value-field="value"
                    text-field="text"
                    v-on:change="getPreferenceForSelectedIptype"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <v-data-table
              v-model="preferenceTable.selected"
              :headers="headers"
              :items="preferenceTable.preference"
              :single-select="preferenceTable.singleSelect"
              :items-per-page="preferenceTable.itemPerPage"
              :server-items-length="preferenceTable.total"
              :options.sync="preferenceTable.pagination"
              @update:items-per-page="changePerPage"
              @update:page="getPreferenceByPagination"
              show-select
              item-key="id"
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
              <template v-slot:item.value="{ item }">
                <b-form-input v-model="item.value"></b-form-input>
              </template>
              <template v-slot:item.description="{ item }">
                <b-form-input v-model="item.description"></b-form-input>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon
                  medium
                  color="dark"
                  class="mr-2"
                  @click="updateItem(item)"
                  v-b-tooltip.hover
                  v-if="canAdd"
                  :title="$t('COMMON.EDIT')"
                >
                  edit
                </v-icon>
                <v-icon
                  medium
                  color="dark"
                  @click="saveDeltem(item)"
                  v-b-modal.confirm-2
                  v-b-tooltip.hover
                  v-if="canRemove"
                  :title="$t('COMMON.DELETE')"
                >
                  delete
                </v-icon>
              </template>
            </v-data-table>
            <!-- ----------- add preference modal -->
            <b-modal id="addPreference" ref="addPreference" title="New">
              <b-container fluid>
                <b-row class="my-3">
                  <b-col sm="5">
                    <label>{{
                      $t("FILTERING.IPACCESS_LISTS.PREFERENCE")
                    }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.iptype.selected"
                      value-field="value"
                      text-field="text"
                      :options="addFormIptypeData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-3">
                  <b-col sm="5">
                    <label for="preferenceValue">{{
                      $t("COMMON.VALUE")
                    }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="preferenceValue"
                      v-model="$v.addForm.value.$model"
                      :state="validateState('value')"
                      :placeholder="$t('COMMON.VALUE')"
                      aria-describedby="input-value-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-value-feedback">
                      {{ $t("VALIDATION.REQUIRED_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-3">
                  <b-col sm="5">
                    <label>{{ $t("COMMON.DESCRIPTION") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-textarea
                      v-model="addForm.desc"
                      :placeholder="$t('COMMON.DESCRIPTION')"
                      rows="4"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="modalClose()">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="savePreference()">
                  {{ $t("COMMON.ADD") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------delete confirm modal------------- -->
            <b-modal
              id="confirm-1"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteItems">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>

            <!-- ---------- delete confirm modal------------- -->
            <b-modal
              id="confirm-2"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneItem">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------delete confirm modal end------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "preference",
  components: {
    KTPortlet,
  },
  data: () => ({
    preferenceTable: {
      singleSelect: false,
      preference: [],
      selected: [],
      itemPerPage: 5,
      total: 0,
      pagination: {},
      position: 0,
    },
    iptype: {
      selected: 1,
    },
    addForm: {
      aid: null,
      value: null,
      desc: null,
      iptype: {
        selected: 1,
      },
    },
    delItem: "",
  }),
  computed: {
    headers: function () {
      return [
        {
          text: this.$t("FILTERING.IPACCESS_LISTS.PREFERENCE"),
          value: "value",
          sortable: false,
        },
        {
          text: this.$t("COMMON.DESCRIPTION"),
          value: "description",
          sortable: false,
        },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    iptypeData: function () {
      return [
        { text: this.$t("MENU.WHITELIST"), value: 1 },
        { text: this.$t("MENU.BLACKLIST"), value: 2 },
      ];
    },
    addFormIptypeData: function () {
      return [
        { text: this.$t("MENU.WHITELIST"), value: 1 },
        { text: this.$t("MENU.BLACKLIST"), value: 2 },
      ];
    },
    canAdd: function () {
      return this.hasPermission("filtering", 5);
    },
    canRemove: function () {
      return this.hasPermission("filtering", 7);
    },
  },
  validations: {
    addForm: {
      value: {
        required,
      },
    },
  },
  created() {
    this.getPreferenceReqest(0, this.preferenceTable.itemPerPage);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title:
          this.$t("MENU.FILTERING") +
          " -> " +
          this.$t("FILTERING.IPACCESS_LISTS.PREFERENCE"),
      },
    ]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.addForm[name];
      return $dirty ? !$error : null;
    },
    /*  -----modal close------- */
    modalClose() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.$refs["addPreference"].hide();
      this.resetForm();
    },
    confirmModalClose(modalRef) {
      this.$refs[modalRef].hide();
    },

    /* -----reset form------ */
    resetForm() {
      this.addForm.value = null;
      this.addForm.desc = null;
    },

    /* ---------read item---------- */
    getPreferenceReqest(offset, limit) {
      this.preferenceTable.preference = [];
      this.preferenceTable.selected = [];
      postRequest({
        action: "getPreferences",
        token: localStorage.id_token,
        offset: offset,
        limit: limit,
        preference: this.iptype.selected,
      }).then((res) => {
        if (res) {
          if (res.returncode) {
            if (res.returndata != null) {
              this.preferenceTable.preference = res.returndata;
              this.preferenceTable.total = res.meta.all;
              this.preferenceTable.position = res.meta.position;
            }
          } else {
            this.preferenceTable.preference = [];
            this.preferenceTable.total = 0;
          }
        }
      });
    },

    getPreferenceForSelectedIptype() {
      this.getPreferenceReqest(0, this.preferenceTable.itemPerPage);
    },

    changePerPage(arg) {
      this.preferenceTable.itemPerPage = arg;
      this.getPreferenceReqest(0, arg);
    },
    getPreferenceByPagination() {
      const pageOffset =
        (this.preferenceTable.pagination.page - 1) *
        this.preferenceTable.itemPerPage;
      this.getPreferenceReqest(pageOffset, this.preferenceTable.itemPerPage);
    },

    /* --------- add preference -------------- */
    openCreateModal() {
      this.addForm.iptype.selected = this.iptype.selected;
      this.$refs["addPreference"].show();
    },
    savePreference() {
      this.$v.addForm.$touch();
      if (this.$v.addForm.value.$anyError) {
        return;
      }
      const selectedIptypeForAdd = this.addForm.iptype.selected;
      const selectedIptypeForPage = this.iptype.selected;
      postRequest({
        action: "addPreference",
        token: localStorage.id_token,
        newpreference: this.iptype.selected,
        newvalue: this.addForm.value,
        newdescription: this.addForm.desc,
      }).then((res) => {
        if (res && res.returncode) {
          this.makeToastVariant("success", res.returndata);
          if (selectedIptypeForAdd == selectedIptypeForPage)
            this.getPreferenceReqest(0, this.preferenceTable.itemPerPage);
          this.modalClose();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    /* ------edit item----- */
    updateItem(item) {
      postRequest({
        action: "updatePreference",
        token: localStorage.id_token,
        editaid: item.id,
        editpreference: this.iptype.selected,
        editvalue: item.value,
        editdescription: item.description,
      }).then((res) => {
        if (res && res.returncode) {
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
          this.getPreferenceReqest(0, this.preferenceTable.itemPerPage);
        }
      });
    },

    /* -------delete item----- */
    saveDeltem(item) {
      this.delItem = item;
    },
    deleteRequest(id) {
      postRequest({
        action: "removePreference",
        token: localStorage.id_token,
        aid: id,
      }).then((res) => {
        if (res && res.returncode) {
          this.makeToastVariant("success", res.returndata);
          for (let i = 0; i < this.preferenceTable.preference.length; i++) {
            if (this.preferenceTable.preference[i].id == id) {
              this.preferenceTable.preference.splice(i, 1);
            }
          }
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.confirmModalClose("delConfirm-modal");
      });
    },
    deleteOneItem() {
      const item = this.delItem;
      this.deleteRequest(item.id);
    },
    deleteItems() {
      if (this.preferenceTable.selected.length > 0) {
        this.preferenceTable.selected.forEach((el) => {
          this.deleteRequest(el.id);
        });
      }
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>
